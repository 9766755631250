<template>
	<div :class="extraClass">
		
		<b-button
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
			:size="isFromPlanilla && !isFromAdmin ? 'lg' : 'md'"
			:block="isFromPlanilla ? true : false"
			:variant="isFromPlanilla && !isFromAdmin ? 'primary' : 'outline-primary'"
			@click="getListFiles()"
		>
			<feather-icon size="1x" :icon="['superadmin', 'admin', 'medisalud'].includes(role) ? 'FileTextIcon':'UploadIcon'" />       
			{{ getButtonText() }} 
		</b-button>
		
		<b-modal
			ref="modal-files"
			centered
			size="xl"
			:title="code ? code.title : titleModal"
			modal-class="modal-primary"
			no-close-on-backdrop
			no-close-on-esc
		>

			<div class="contentModal mt-1">{{ contentModal }} {{ !['superadmin', 'admin', 'medisalud'].includes(role) ? 'a presentar' : ''}} </div>
			<div v-html="code ? code.description : descriptionModal" class="descriptionModal my-2"></div>
			
			<template v-if="!['superadmin', 'admin', 'medisalud'].includes(role)">
				<hr>
					<div class="my-1">
						<vue-dropzone  
							ref="myVueDropzoneRef" 
							id="myVueDropzone"						
							@vdropzone-success-multiple="vsuccess"				
							@vdropzone-sending-multiple="vsending"
							@vdropzone-error="verror"
							:options="dropzoneOptions"
							:headers="headers"
							:useCustomSlot="true"				
						>
							<div class="dropzone-custom-content">
								<h4 class="dropzone-custom-title"><strong>¡Arrastre o haga click aquí para cargar contenido!</strong></h4>
								<div class="subtitle">{{ this.acceptedAllFiles ? 'solo archivos PNG ó JPG ó JPEG ó PDF ó EXCEL' : 'solo archivos PDF ó EXCEL' }}</div>
							</div>
						</vue-dropzone>
					</div>
				<hr>
			</template>				

			<table class="table table-bordered text-center w-100 mt-3 mb-2">
				<thead>
					<tr>
						<th>#</th>
						<th colspan="2">Archivo</th>
						<th>Fecha de carga</th>
						<th>Opciones</th>
					</tr>
				</thead>
				<tbody>						
					<tr v-if="tableFiles.length == 0">
						<td colspan="5">No hay ningún archivo cargado.</td>
					</tr>
					<tr v-for="(item, index) in tableFiles" :key="index">							
						<td>{{ index + 1 }} </td>	
						<td colspan="2">{{ item.archivo }}</td>
						<td>{{ item.date_uploaded }}</td>
						<td>
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.v-info
								variant="outline-info"
								class="mr-1 px-1"                                                 
								title="Descargar"
							>
								<b-link @click='viewFile(item.id)'>
									<feather-icon icon="DownloadIcon" style="color:#00cfe8"/>
								</b-link>
							</b-button>
															
							<b-button
								v-if="!['superadmin', 'admin', 'medisalud'].includes(role)"
								v-ripple.400="'rgba(234, 84, 85, 0.15)'"
								v-b-tooltip.hover.v-danger
								variant="outline-danger"								
								class="px-1"
								title="Eliminar"
								@click="deleteBtn(item.id)"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>

			<template #modal-footer="{cancel}">
				<b-button variant="outline-danger" @click="cancel()">
					CERRAR
				</b-button>
			</template>
		</b-modal>
			
	</div>	
</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import uploadFileStoreModule from './uploadFileStoreModule';
	import { useToast } from 'vue-toastification/composition';
	import { ref, onUnmounted } from '@vue/composition-api';
	import {  VBModal,VBTooltip } from 'bootstrap-vue';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import Ripple from 'vue-ripple-directive';
	import vue2Dropzone from 'vue2-dropzone';
	import Swal from 'sweetalert2';
	import router from '@/router';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';

	export default {
		props: ["code", "getQuestionFiles", "isFromPlanilla", "isFromAdmin", "acceptedAllFiles", "extraClass"],
		components: {			
			vueDropzone: vue2Dropzone,
			ToastificationContent
		},
		directives: {
			'b-modal': VBModal,
			'b-tooltip': VBTooltip,
			Ripple
		},
		data() {
			return {
				PublicUrl: process.env.VUE_APP_URL,
				role: JSON.parse(localStorage.getItem('userData')).role,
				titleModal: "",
				descriptionModal: "",			
				contentModal: "Descripción de la información",			
				tableFiles: []
			}
		},
		methods: {
			getButtonText () {
				let verb = ['superadmin', 'admin', 'medisalud'].includes(this.role) ? 'Ver' : 'Cargar'
				let subject = this.isFromPlanilla ? 'Boletas' : 'Archivos'
				
				let text = this.isFromAdmin ? (`${verb} ${subject}`).toUpperCase() : `${verb} ${subject}`
				return text			
			},
			vsending(files, xhr, formData) {
				formData.append('form_id', router.currentRoute.params.id);
				formData.append('file_question_id', this.code.id);
				formData.append('files_count', files.length);
			},
			verror (files, response, xhr) {
				this.getListFiles();

				Swal.fire({
					title: '¡Error!',
					text: response.message,
					icon: 'error',
					showConfirmButton: false,
					timer: 3500
				});

				if (this.getQuestionFiles) this.getQuestionFiles();
			},
			vsuccess(file, response){		
				if(response) 
				{
					this.getListFiles();

					Swal.fire({
						title: 'El contenido se ha cargado exitosamente!',
						icon: 'success',
						showConfirmButton: false,
						timer: 2100,
						customClass: {
							confirmButton: 'btn btn-success',
						},
						buttonsStyling: false,
					});
					
					this.$refs['myVueDropzoneRef'].removeFile(file);

					if (this.getQuestionFiles) this.getQuestionFiles();
				}
			},
			getListFiles(){
				this.tableFiles = [];
				
				axios.get(`/files?form_id=${router.currentRoute.params.id}&file_question_id=${this.code.id}`)
				.then(response => {
									
					if(response.data)
					{
						response.data.files.map( item => {
							this.tableFiles.push({
								id: item.id,
								archivo: item.original_name,
								date_uploaded: moment(item.createdAt).format('DD/MM/YYYY h:mm A'),
								path: item.document_storage,
							});						
						});

						this.$refs['modal-files'].show();
					}				
				});			
			},
			deleteBtn(id){

				const payload = {
					form_id: router.currentRoute.params.id
				}
				
				axios.delete(`/files/${id}/delete`, payload)
				.then(response => {
					
					this.getListFiles();

					if (this.getQuestionFiles) this.getQuestionFiles();
					
					Swal.fire({
						title: response.data.message,
						icon: 'success',
						showConfirmButton: false,
						timer: 2000,
						customClass: {
							confirmButton: 'btn btn-success'
						},
						buttonsStyling: false
					});  
				})
				.catch( (err) => {
					Swal.fire({
						title: 'Error',
						text: 'Ups, hubo un error al eliminar el archivo!',
						icon: 'error',
						customClass: {
							confirmButton: 'btn btn-danger',
						},
						buttonsStyling: false,
					});
				});			
			},	
		},
		setup () {
			// USE TOAST
			const toast = useToast();

			const UPLOAD_FILE_APP_STORE_MODULE_NAME = 'app-upload-file';

			// REGISTER MODULE
			if (!store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)) store.registerModule(UPLOAD_FILE_APP_STORE_MODULE_NAME, uploadFileStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)) store.unregisterModule(UPLOAD_FILE_APP_STORE_MODULE_NAME);
			});

			const headers = ref({ 'Content-Type': 'multipart/form-data' });

			const dropzoneOptions = ref({
				headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				url: `${process.env.VUE_APP_HOST}/api/dashboard/files/upload`,
				method: 'post',
				paramName: 'files',
				autoDiscover: false,
				maxFiles: 10,
				timeout: 180000,
				maxThumbnailFilesize: 10,
				parallelUploads: 10,
				acceptedFiles: '.pdf,.xls,.xlsx',				
				addRemoveLinks: false,		
				autoProcessQueue: true,					
				thumbnailWidth: 140,
				thumbnailHeight: 140,
				dictRemoveFile: "Eliminar",
				uploadMultiple: true
			});

			const viewFile = (id) => {

				store.dispatch('app-upload-file/viewFile', { id })
					.then( response => {

						window.open(response.data.url, '_blank');

					})
					.catch( () => {

						toast({
							component: ToastificationContent,
							props: {
								title: 'Error al descargar el archivo',
								icon: 'AlertTriangleIcon',
								variant: 'danger'
							}
						});

					});

			};

			return {
				headers,
				dropzoneOptions,

				viewFile
			}
		}
	
	}

</script>

<style scoped>

	.modal-header {
		text-transform: uppercase !important; 
	}

	.descriptionModal {
		font-size: .995rem;	
	}

	.contentModal {
		font-size: 1.2rem;	
		font-weight: bold;
	}

</style>