<template>
    <div>
        <form-page :formData="formData" />            
    </div>
</template>

<script>

    import FormPage from './FormPage';
    import router from '@/router';

    export default {
        components: {
            FormPage
        },
        data(){
            return {
                formData:  router.currentRoute.query.form
            }  
        }
    }

</script>