<template>
    <div>

        <b-button
            id="buttonCommentsId"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-dark"
            class="btn-icon rounded-circle"
            v-b-tooltip.hover.on.v-dark
            :title="medisalud ? 'Comentarios MediSalud' : 'Comentarios Revisor'"
            @click="getComments()"
        >
            <feather-icon icon="MessageSquareIcon" />
        </b-button>
        
        <b-modal
            ref="modal-comments"
            :id="`modal-${question ? question.id : 'y'}`"
            centered
            size="lg"
            :title="question ? question.title : titleModal"
            modal-class="modal-primary"
            no-close-on-backdrop
            no-close-on-esc
        >

            <div class="contentModal mt-1">{{ medisalud ? 'Comentario MediSalud' : 'Comentario Revisor' }}</div>            
            
            <b-form-textarea
                id="textarea"
                :disabled="onlyview"   
                placeholder="Ingrese texto..."
                v-model="commentModal"
                class="my-2"
                rows="5"
            >                
            </b-form-textarea>

            <template #modal-footer>

                <b-button
                    v-if="!onlyview"
                    variant="primary"
                    class="float-right"
                    @click="updateComments()"
                >
                    COMENTAR
                </b-button>

                <b-button
                    v-if="!onlyview"
                    variant="outline-danger" 
                    @click="cancelar()"
                >
                    CANCELAR
                </b-button>

                <b-button
                    v-if="onlyview"
                    variant="outline-danger" 
                    @click="cancelar()"
                >
                    CERRAR
                </b-button>

            </template>
        </b-modal>

    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import Ripple from 'vue-ripple-directive';
    import axios from '@axios';

    export default {
        props: {
            question: Object,
            form_id: {
                type: [String, Number],
                required: true
            },
            medisalud: {
                type: Boolean,
                default: false
            },
            onlyview: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ToastificationContent
        },
        directives: {
            Ripple
        },
        data() {
            return {
                titleModal: '',
                commentModal: ''
            }
        },
        methods: {
            async getComments(){
                try {

                    const payload = { question: this.question.id, medisalud: this.medisalud }

                    const response = await axios.post(`/reviser/form-a/${this.form_id}/get-comment`, payload);
                    this.commentModal = response.data.comment;

                    this.$refs['modal-comments'].show();

                } catch (error) {

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: error.response.data.message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })

                }
            },
            async updateComments(){
                
                try {

                    const payload = {
                        comment : this.commentModal,
                        question: this.question.id,
                        medisalud: this.medisalud
                    }

                    const response = await axios.put(`/reviser/form-a/${this.form_id}/update-comment`, payload);

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    })

                    this.$refs['modal-comments'].hide();

                } catch (error) {

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: error.response.data.message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })

                }

            },
            cancelar(){
                this.$refs['modal-comments'].hide();
            }
        }
    }

</script>