<template>
  <div>
        <b-row>
            <b-col
                sm="12"
            >
                <statistic-card-horizontal
                    icon="UserIcon"
                    color="info"
                    :statistic="providerV"
                    statistic-title="Proveedor"
                />
            </b-col>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="TargetIcon"
                    color="info"
                    :statistic="score ? score : scoreV + ' %'"
                    statistic-title="Porcentaje obtenido"
                />
            </b-col>
            <b-col
                md="6"
                sm="12"
            >
                <statistic-card-horizontal
                    icon="CalendarIcon"
                    color="info"
                    :statistic="dateV"
                    statistic-title="Fecha de Evaluación"
                />
            </b-col>
        </b-row>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import axios from '@axios'
import router from '@/router';
import moment from 'moment';

export default {
    props: {
        score: String,
    },
    components: {
        StatisticCardHorizontal,
    },
    data(){
        return {
            providerV: "",
            scoreV: 0,       
            dateV: "",
        }
    },
    watch: {
        score() {
            if(this.score){
                this.scoreV = this.score;    
            }            
        }
    },
    beforeMount() {        
        this.getDataForm();
    },
    methods: {
        getDataForm(){
           axios.get(`/reviser-form/${router.currentRoute.params.id}`)
           .then(response => {
               this.scoreV = response.data.score;
               this.dateV = moment(response.data.date_dispatch_review).format("D/M/YYYY")
               this.providerV = response.data.provider.user.names;
           });
        }
    }
}
</script>

<style>

</style>