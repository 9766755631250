import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        /*
        fetchFiles(ctx, { formId, questionId }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/files', { formId, questionId })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        */
        viewFile(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/files/${id}/view`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        /*
        deleteFile(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/files/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
        */
    }
}